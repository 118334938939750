// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  ArrayInput,
  CreateBase,
  EditBase,
  List,
  regex,
  required,
  SimpleForm,
  SimpleFormIterator,
  useCreate,
  useEditContext,
  useListContext,
  usePermissions,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Typography } from '@mui/material';

import './styles.css';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar, HazPerms } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';
import { copyToClipboard } from '../utils/utils';

const headers = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'updatedAt',
    header: 'Last Updated',
  },
  {
    key: 'list_count',
    header: 'List Members',
  },
];

export const Table = () => {
  const { permissions, isLoading } = usePermissions();
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data || isLoading) return null;

  const clone = structuredClone(data);

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  clone?.forEach((result, idx) => {
    // conditionally add edit link
    if (HazPerms(permissions, 'policies', 'edit')) {
      result.edit = <MyEdit url={`/domainlist/${result.id}`} key={`edit-${idx}`} />;
      if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    }
    result.list_count = result.list?.length || 0;
    result.updatedAt = <Typography variant="body2"> {new Date(result.updated_at).toLocaleString()}</Typography>;
  });
  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Domain Lists?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'domainlist',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Domain List',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable data={clone} headers={headers} showActions={true} zebra={false} addRow={() => redirect('create', 'domainlist')} bulkAction={confirmBulkDelete} />
    </>
  );
};

export const DomainListForm = ({ props, isEdit = false }) => {
  const [create] = useCreate();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const { handleError } = useHandleError();
  const notificationCtx = React.useContext(NotificationContext);

  const { isLoading, isFetching } = useEditContext();
  if (isLoading || isFetching) {
    return (
      <div className="container">
        <div className="loading">
          <div className="loading__letter">L</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">i</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
      </div>
    );
  }

  const handleCopy = () => {
    const success = copyToClipboard(document.getElementById('list-id-input').value);
    if (success) {
      notificationCtx.add({ msg: 'Domain list ID copied to clipboard', details: '', status: 'success' });
    }
  };

  const postSave = async (data) => {
    // remove any empty values from the list
    data.list = data.list.filter((r) => r !== '');
    if (isEdit) {
      update(
        'domainlist',
        { id: data.id, data: data },
        {
          onSuccess: () => {
            redirect('/domainlist');
            notificationCtx.add({ msg: 'Record updated!', details: '', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err, resource: 'domainlist' });
          },
        },
      );
    } else {
      create(
        'domainlist',
        { data },
        {
          onSuccess: () => {
            redirect('/domainlist');
            notificationCtx.add({ msg: 'Record created!', details: '', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err, resource: 'domainlist', create: true });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      toolbar={<CustomToolbar redirectPath={'/domainlist'} resource="domainlist" showDelete={isEdit ? true : false} />}
      onSubmit={postSave}
    >
      <CarbonTextInputField
        source="name"
        label="Name"
        validate={[required('Name is required'), regex(/^[a-zA-Z0-9_-]+$/, 'Enter a valid name consisting of letters, numbers, underscores or hyphens.')]}
        {...props}
      />
      <CarbonTextInputField source="description" label="description" id="description" />
      <div className="myDiv" onClick={isEdit ? handleCopy : null}>
        <CarbonTextInputField readOnly label="List ID" source="id" />
      </div>
      <ArrayInput label="Domains" source="list">
        <SimpleFormIterator
          fullWidth
          sx={{
            '& .RaSimpleFormIterator-line': {
              borderBottom: 'none',
            },
          }}
        >
          <CarbonTextInputField label=""></CarbonTextInputField>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export const DomainListCreate = ({ props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/domainlist');
    refresh();
    notificationCtx.add({ msg: 'Domain List created.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err, resource: 'domainlist', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={'/domainlist'} />
      <DomainListForm
        defaultValues={{
          name: '',
          description: '',
          list: [],
        }}
      />
    </CreateBase>
  );
};

export const DomainListEdit = ({ props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/domainlist');
    refresh();
    notificationCtx.add({ msg: 'Domain List updated.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err, resource: 'domainlist' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={'/domainlist'} />
      <DomainListForm {...props} isEdit={true} />
    </EditBase>
  );
};

export const DomainListList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/domainlist'} />
      <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
        <Table />
      </List>
    </>
  );
};
