// © Copyright IBM Corp. 2022, 2024

// in App.js
import * as React from 'react';

import { Admin, CustomRoutes, defaultTheme, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { authFactory, fetchJsonWithCookie } from './authProvider';
import { HazPerms } from './component/customComponents';
import { MyLayout } from './component/mainMenu';
import config from './config';
import { NotificationProvider } from './context/notificationContext';
import { Dashboard } from './page/dashboard';
import LoginPage from './page/loginPage';
import { AccessTokenCreate, AccessTokenList } from './resource/accessToken';
import { CACertificateCreate, CACertificateEdit, CACertificateList, CACreate, CAEdit, CAList } from './resource/ca';
import { DomainListCreate, DomainListEdit, DomainListList } from './resource/domainlist';
import { EntityCreate, EntityEdit, EntityList } from './resource/entity';
import {
  EnvironmentClientCreate,
  EnvironmentClientEdit,
  EnvironmentClientList,
  EnvironmentCreate,
  EnvironmentEdit,
  EnvironmentList,
} from './resource/environment';
import { GlobalDomainListList } from './resource/globaldomainlist';
import { HelpPage } from './resource/help';
import { IDPList } from './resource/idp';
import { PolicyCreate, PolicyEdit, PolicyList } from './resource/policy';
import { PolicyChainCreate, PolicyChainEdit, PolicyChainList } from './resource/policychain';
import { ProfileShow } from './resource/profile';
import { ReportList } from './resource/report';
import { SinkCreate, SinkEdit, SinkList } from './resource/sink';
import DnsIcon from '@mui/icons-material/Dns';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import KeyIcon from '@mui/icons-material/Key';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PeopleIcon from '@mui/icons-material/People';
import TrafficIcon from '@mui/icons-material/Traffic';
import VerifiedIcon from '@mui/icons-material/Verified';
import drfProvider from 'ra-data-django-rest-framework-v4';

const dataProvider = drfProvider(`${config.apiUrl}/v1`, fetchJsonWithCookie);

const raptorTheme = {
  ...defaultTheme,
};

const MyAuth = authFactory(`${config.apiUrl}/v1/auth`);

const initialState = {
  admin: { ui: { sidebarOpen: true, viewVersion: 0 } },
};

const App = () => (
  <NotificationProvider>
    <Admin
      initialState={initialState}
      theme={raptorTheme}
      loginPage={LoginPage}
      authProvider={MyAuth}
      dataProvider={dataProvider}
      requireAuth
      disableTelemetry
      dashboard={Dashboard}
      layout={MyLayout}
    >
      {(permissions) => (
        <>
          <Resource icon={FolderSharedIcon} options={{ label: 'Identity Providers' }} name="idp" list={IDPList} />
          {HazPerms(permissions, 'policies') && (
            <Resource
              icon={DnsIcon}
              options={{ label: 'My Lists' }}
              name="domainlist"
              list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? DomainListList : null}
              edit={HazPerms(permissions, 'policies', 'edit') ? DomainListEdit : null}
              create={HazPerms(permissions, 'policies', 'create') ? DomainListCreate : null}
            />
          )}
          {HazPerms(permissions, 'policies') && (
            <Resource
              icon={DnsIcon}
              options={{ label: 'Global Domain Lists' }}
              name="globaldomainlist"
              list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? GlobalDomainListList : null}
            />
          )}
          {/*<Resource icon={FolderSharedIcon}*/}
          {/*          options={{label: 'Endpoint Providers'}}*/}
          {/*          name="endpointproviders"*/}
          {/*          list={IDPList}*/}
          {/*/>*/}
          <Resource icon={PeopleIcon} options={{ label: 'Entities' }} name="entity" list={EntityList} create={EntityCreate} edit={EntityEdit} />
          {HazPerms(permissions, 'policies') && (
            <Resource
              icon={TrafficIcon}
              options={{ label: 'Policy Chains' }}
              name="policy_chain"
              list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? PolicyChainList : null}
              create={HazPerms(permissions, 'advanced_policies', 'create') ? PolicyChainCreate : null}
              edit={HazPerms(permissions, 'advanced_policies', 'edit') ? PolicyChainEdit : null}
            />
          )}
          {HazPerms(permissions, 'policies') && (
            <Resource
              icon={TrafficIcon}
              options={{ label: 'Policies' }}
              name="policy"
              list={HazPerms(permissions, 'policies', 'read') || HazPerms(permissions, 'policies', 'create') ? PolicyList : null}
              edit={HazPerms(permissions, 'policies', 'edit') ? PolicyEdit : null}
              create={HazPerms(permissions, 'policies', 'create') ? PolicyCreate : null}
            />
          )}

          {/*<Resource icon={FingerprintIcon}*/}
          {/*          options={{label: 'Classifiers'}}*/}
          {/*          name="classifier"*/}
          {/*          list={IdentityList}*/}
          {/*          show={IdentityList}*/}
          {/*/>*/}
          {/*<Resource icon={RouteIcon}*/}
          {/*          options={{label: 'Actions'}}*/}
          {/*          name="action"*/}
          {/*          list={PolicyList}*/}
          {/*          show={PolicyShow}*/}
          {/*/>*/}
          {/*<Resource icon={DnsIcon}*/}
          {/*          name="endpoint"*/}
          {/*          options={{label: 'Endpoints'}}*/}
          {/*          list={EndpointList}*/}
          {/*          show={EndpointShow}*/}
          {/*/>*/}

          {HazPerms(permissions, 'environments') && (
            <Resource
              icon={MultipleStopIcon}
              name="environment"
              options={{ label: 'Environments' }}
              list={HazPerms(permissions, 'environments', 'read') ? EnvironmentList : null}
              create={HazPerms(permissions, 'environments', 'create') ? EnvironmentCreate : null}
              edit={HazPerms(permissions, 'environments', 'edit') ? EnvironmentEdit : null}
            >
              <Route path=":id/client" element={<EnvironmentClientList />} />
              <Route path=":id/client/create" element={<EnvironmentClientCreate />} />
              <Route path=":id/client/:clientId" element={<EnvironmentClientEdit />} />
              <Route path=":id/fingerprint/available" />
            </Resource>
          )}
          <Resource icon={KeyIcon} name="accesstoken" options={{ label: 'Access Tokens' }} list={AccessTokenList} create={AccessTokenCreate} />

          <Resource icon={VerifiedIcon} name="ca" options={{ label: 'Certificate Authorities' }} list={CAList} create={CACreate} edit={CAEdit}>
            <Route path=":id/certificate" element={<CACertificateList />} />
            <Route path=":id/certificate/create" element={<CACertificateCreate />} />
            <Route path=":id/certificate/:certificateId" element={<CACertificateEdit />} />
          </Resource>

          <Resource options={{ label: 'Logs' }} name="reports/logs" list={ReportList} />
          <Resource options={{ label: 'Sinks' }} name="sinks" list={SinkList} create={SinkCreate} edit={SinkEdit} />
          {/* <Resource options={{ label: 'Metrics' }} name="reports/metrics" list={Metrics} /> */}

          <CustomRoutes>
            <Route path="/help" element={<HelpPage />} />
            <Route key="profile" path="/profile" element={<ProfileShow />} />
          </CustomRoutes>
        </>
      )}
    </Admin>
  </NotificationProvider>
);

export default App;
