// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useChoicesContext, useInput } from 'react-admin';

import { TextArea as CarbonTA, TextInput as CarbonTI, MultiSelect, Select, SelectItem } from '@carbon/react';

import { Typography } from '@mui/material';

const CarbonTextInputField = (props) => {
  const { onChange, onBlur, label, id = '', ...rest } = props;
  const {
    field,
    fieldState: { invalid, error },
    isRequired,
  } = useInput({
    onChange,
    onBlur,
    ...rest,
  });

  const updatedLabel = () => {
    if (isRequired) {
      const s = label.replace(/\*+$/, '');
      return (
        <Typography as="div">
          {s}{' '}
          <Typography color="red" display={'inline'}>
            *
          </Typography>
        </Typography>
      );
    } else {
      return label;
    }
  };

  return (
    <CarbonTI
      {...field}
      {...rest}
      labelText={updatedLabel()}
      invalid={invalid}
      invalidText={invalid ? JSON.parse(error?.message.replaceAll('@', '').replace('react-admin', '')).message : ''}
      required={isRequired}
      id={id || (label && `${label.replace(' ', '-').toLowerCase()}-input`) || `input-${Math.floor(Math.random() * 100)}`}
    />
  );
};

const CarbonTextField = (props) => {
  const { label, id = '', value } = props;

  return (
    <CarbonTI
      readOnly
      labelText={''}
      id={id || (label && `${label.replace(' ', '-').toLowerCase()}-input`) || `input-${Math.floor(Math.random() * 100)}`}
      value={value}
    />
  );
};

const CarbonTextArea = (props) => {
  const { onChange, onBlur, label, id = '', ...rest } = props;
  const {
    field,
    fieldState: { invalid, error },
    isRequired,
  } = useInput({
    onChange,
    onBlur,
    ...rest,
  });

  return (
    <div style={{ width: '100%' }}>
      <CarbonTA
        {...field}
        rows={props.rows || 10}
        label={label}
        labelText={label}
        invalid={invalid}
        invalidText={invalid ? JSON.parse(error?.message.replaceAll('@', '').replace('react-admin', '')).message : ''}
        required={isRequired}
        id={id || (label && `${label.replace(' ', '-').toLowerCase()}-input`) || `input-${Math.floor(Math.random() * 100)}`}
      />
    </div>
  );
};

const MyMultiSelect = (props) => {
  const ref = React.useRef();

  const { onChange, onBlur } = props;
  const { field } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  const [selected, setSelected] = React.useState(field.value);
  const managed_fields = { ...field };
  delete managed_fields.value;

  return (
    <MultiSelect
      {...managed_fields}
      id="MyMultiSelect"
      label=""
      titleText={props.title || ''}
      items={props.choices || []}
      initialSelectedItems={props.choices?.filter((c) => selected.includes(c.id))}
      className="MyMultiSelect"
      ref={ref}
      itemToString={(item) => (item ? item.text : '')}
      onChange={(e) => {
        setSelected(e.selectedItems.map((i) => i.id));
        field.onChange(e.selectedItems.map((i) => i.id));
      }}
    />
  );
};

const MySelect = (props) => {
  const { availableChoices } = useChoicesContext();
  const { onChange, onBlur, label, id = '', ...rest } = props;
  const { field } = useInput({
    onChange,
    onBlur,
    ...rest,
  });

  const choices = availableChoices || props.choices || [];

  return (
    <Select {...field} id={id} labelText={label || ''} value={field.value}>
      {props.showEmpty ? <SelectItem value="" text="" /> : null}
      {choices?.map((c) => (
        <SelectItem value={c.id} text={c.name} key={`${c.id}-${c.name}`} />
      ))}
    </Select>
  );
};

export { CarbonTextArea, CarbonTextField, CarbonTextInputField, MyMultiSelect, MySelect };
