// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useListContext } from 'react-admin';

import {
  Button,
  DataTable,
  DataTableSkeleton,
  Pagination,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react';
import { Add, TrashCan } from '@carbon/react/icons';

const MyTable = ({ data, headers, addRow, bulkAction, zebra = false, showActions = true, title = '', description = '', showPagination = true }) => {
  const { isPending, page, perPage, total, setPage, setPerPage } = useListContext();
  if (isPending || !data) return <DataTableSkeleton aria-label="loading table" headers={headers} compact zebra />;

  const handleChange = (event) => {
    setPerPage(event.pageSize);
    setPage(event.page);
  };

  return (
    <>
      <DataTable rows={data} headers={headers} useZebraStyles={zebra}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getToolbarProps,
          getBatchActionProps,
          selectedRows,
          getTableProps,
          getTableContainerProps,
          selectRow,
        }) => {
          const batchActionProps = {
            ...getBatchActionProps({
              onSelectAll: () => {
                rows.map((row) => {
                  if (!row.isSelected) {
                    selectRow(row.id);
                  }
                });
              },
            }),
          };
          return (
            <TableContainer {...getTableContainerProps()} title={title} description={description}>
              {showActions && (
                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                  <TableBatchActions {...batchActionProps}>
                    <TableBatchAction
                      tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                      renderIcon={TrashCan}
                      onClick={() => bulkAction(selectedRows)}
                    >
                      Delete
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent>
                    <Button kind="ghost" renderIcon={Add} onClick={addRow} style={{ marginRight: '3px' }}>
                      Create
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
              )}

              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {showActions && <TableSelectAll {...getSelectionProps()} />}
                    {headers.map((header) => {
                      return (
                        <TableHeader key={`header-${header.key}`} props={{ ...getHeaderProps({ header }) }}>
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={`table-row-${i}`} props={{ ...getRowProps({ row }) }}>
                      {showActions && (
                        <TableSelectRow
                          {...getSelectionProps({
                            row,
                          })}
                        />
                      )}
                      {row.cells.map((cell) => (
                        <TableCell key={`cell-${cell.id}`}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      {showPagination && (
        <Pagination
          backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          onChange={handleChange}
          page={page || 1}
          pageSize={perPage || 5}
          pageSizes={[2, 5, 10, 20, 30, 40, 50]}
          size="md"
          totalItems={total}
        />
      )}
    </>
  );
};

export { MyTable };
