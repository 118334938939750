// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { useState } from 'react';

import { MenuItemLink, useLogout, usePermissions } from 'react-admin';
import { Link } from 'react-router-dom';

import { Upstream, UserAvatar, UserSettings } from '@carbon/icons-react';
import {
  Header,
  HeaderContainer,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuButton,
  HeaderName,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
  SkipToContent,
  Theme,
} from '@carbon/react';

import DnsIcon from '@mui/icons-material/Dns';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import KeyIcon from '@mui/icons-material/Key';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PeopleIcon from '@mui/icons-material/People';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrafficIcon from '@mui/icons-material/Traffic';
import VerifiedIcon from '@mui/icons-material/Verified';

import '../App.scss';

import { HazPerms } from './customComponents';

export const MyLayout = ({ children }) => {
  // const { isSideNavExpanded, setIsSideNavExpanded } = useHeaderContext();
  const { permissions, isLoading } = usePermissions();
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(true);
  const logout = useLogout();

  if (isLoading) return null;

  const checkExpanded = (substrings) => {
    return substrings.some((substring) => window.location.href.includes(substring));
  };

  return (
    <Theme theme="g10">
      <HeaderContainer
        render={({ onClickSideNavExpand }) => (
          <>
            <Header aria-label="Zero Trust Connect">
              <SkipToContent />
              <HeaderMenuButton
                aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
                onClick={() => {
                  onClickSideNavExpand(!isSideNavExpanded);
                  setIsSideNavExpanded(!isSideNavExpanded);
                }}
                isActive={isSideNavExpanded}
                aria-expanded={isSideNavExpanded}
              />
              <HeaderName href="/" prefix="IBM">
                [Zero Trust Connect]
              </HeaderName>
              <HeaderGlobalBar>
                <HeaderGlobalAction aria-label="My Profile" tooltipAlignment="end" className="action-icons">
                  {/* <UserMenu> */}
                  <MenuItemLink to="/profile" leftIcon={<UserAvatar size="20" />} />
                  {/* </UserMenu> */}
                </HeaderGlobalAction>
                <HeaderGlobalAction aria-label="Log Out" tooltipAlignment="end" onClick={logout} style={{ marginRight: '5px' }}>
                  <ExitIcon />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
            </Header>
            <SideNav aria-label="Side navigation" href="#main-content" expanded={isSideNavExpanded} isPersistent={false}>
              <SideNavItems>
                <SideNavMenu title="ZNS" defaultExpanded={checkExpanded(['/ca', '/policy', '/environment', '/globaldomainlist', '/domainlist'])}>
                  <SideNavMenuItem isActive={window.location.href.includes('/ca')} as={Link} to="/ca">
                    <VerifiedIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Certificate Authorities</p>
                  </SideNavMenuItem>
                  <SideNavMenu className="menu_item_child" title="Policies" defaultExpanded={checkExpanded(['/#/policy_chain', '/#/policy'])}>
                    {HazPerms(permissions, 'advanced_policies') && (
                      <SideNavMenuItem isActive={window.location.href.includes('/#/policy_chain')} as={Link} to="/policy_chain">
                        <TrafficIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                        <p className="sidemenu-p">Policy Chains</p>
                      </SideNavMenuItem>
                    )}
                    {HazPerms(permissions, 'policies') && (
                      <SideNavMenuItem isActive={!!window.location.href.match(/\/#\/policy$/)?.length} as={Link} to="/policy">
                        <TrafficIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                        <p className="sidemenu-p">Policies</p>
                      </SideNavMenuItem>
                    )}
                  </SideNavMenu>

                  {HazPerms(permissions, 'environments') && (
                    <SideNavMenuItem isActive={window.location.href.includes('/#/environment')} as={Link} to="/environment">
                      <MultipleStopIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                      <p className="sidemenu-p">Environments</p>
                    </SideNavMenuItem>
                  )}

                  <SideNavMenu className="menu_item_child" title="Domain Lists" defaultExpanded={checkExpanded(['/#/domainlist', '/#/globaldomainlist'])}>
                    <SideNavMenuItem isActive={window.location.href.includes('/#/domainlist')} as={Link} to="/domainlist">
                      <DnsIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                      <p className="sidemenu-p">My Lists</p>
                    </SideNavMenuItem>
                    <SideNavMenuItem isActive={window.location.href.includes('/#/globaldomainlist')} as={Link} to="/globaldomainlist">
                      <DnsIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                      <p className="sidemenu-p">Global Lists</p>
                    </SideNavMenuItem>
                  </SideNavMenu>
                </SideNavMenu>

                <SideNavMenu title="Identities" defaultExpanded={checkExpanded(['/#/entity', '/#/idp'])}>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/entity')} as={Link} to="/entity">
                    <PeopleIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Entities</p>
                  </SideNavMenuItem>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/idp')} as={Link} to="/idp">
                    <FolderSharedIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Identity Providers</p>
                  </SideNavMenuItem>
                </SideNavMenu>
                <SideNavMenu title="Settings" defaultExpanded={checkExpanded(['/#/accesstoken', '/#/help', '/#/profile'])}>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/accesstoken')} as={Link} to="/accesstoken">
                    <KeyIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Access Tokens</p>
                  </SideNavMenuItem>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/help')} as={Link} to="/help">
                    <QuestionMarkIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Help</p>
                  </SideNavMenuItem>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/profile')} as={Link} to="/profile">
                    <UserSettings className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Profile</p>
                  </SideNavMenuItem>
                </SideNavMenu>
                <SideNavMenu title="Observability" defaultExpanded={checkExpanded(['/#/reports/logs', '/#/reports/metrics', '/#/sinks'])}>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/reports/logs')} as={Link} to="/reports/logs">
                    <SummarizeIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Logs</p>
                  </SideNavMenuItem>
                  <SideNavMenuItem isActive={window.location.href.includes('/#/sinks')} as={Link} to="/sinks">
                    <Upstream className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Sinks</p>
                  </SideNavMenuItem>
                  {/* <SideNavMenuItem isActive={window.location.href.includes('/#/reports/metrics')} as={Link} to="/reports/metrics">
                    <SummarizeIcon className="sidemenu-icon" sx={{ fontSize: 16 }} />
                    <p className="sidemenu-p">Metrics</p>
                  </SideNavMenuItem> */}
                </SideNavMenu>
              </SideNavItems>
            </SideNav>
            <div
              style={{
                paddingLeft: isSideNavExpanded ? '256px' : '5px',
                marginTop: '55px',
              }}
            >
              {children}
            </div>
          </>
        )}
      />
    </Theme>
  );
};
