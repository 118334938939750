// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { NotificationContext } from '../context/notificationContext';

export const useHandleError = () => {
  const notificationCtx = React.useContext(NotificationContext);

  const handleError = ({ err, resource, create }) => {
    for (const [key, value] of Object.entries(err?.body?.details)) {
      for (const [k, v] of Object.entries(value)) {
        notificationCtx.add({
          msg: `Failed to ${create ? 'create' : 'update'} ${resource}.`,
          details: `${key}: ${Array.isArray(value) ? '' : k + ':'} ${v}`,
          status: 'error',
        });
      }
    }
  };

  return { handleError };
};
