// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  BooleanInput,
  ChipField,
  CreateBase,
  EditBase,
  List,
  ReferenceArrayField,
  regex,
  required,
  SimpleForm,
  SingleFieldList,
  useDataProvider,
  useListContext,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Button } from '@carbon/react';

import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar } from '../component/customComponents';
import { JsonEditor } from '../component/jsonEditor';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextArea, CarbonTextInputField, MyMultiSelect } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const caHeaders = [
  {
    key: 'id',
    header: 'id',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'environmentChip',
    header: 'Environments',
  },
  {
    key: 'certificates',
    header: 'Certificates',
  },
  {
    key: 'edit',
    header: 'Edit',
  },
];

const certificateHeaders = [
  {
    key: 'id',
    header: 'ID',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'Description',
  },
  {
    key: 'fingerprint',
    header: 'Certificate',
  },
  { key: 'edit', header: 'Edit' },
];

export const CATable = () => {
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();
  const [clone, setClone] = React.useState(null);

  React.useEffect(() => {
    const processData = async () => {
      if (!clone && data) {
        const temp = structuredClone(data);

        temp?.forEach((result, idx) => {
          result.edit = <MyEdit url={`/ca/${result.id}`} key={`edit-${idx}`} />;
          if (!caHeaders.some((e) => e.key === 'edit')) caHeaders.push({ key: 'edit', header: 'Edit' });
          result.list_count = result.list?.length || 0;
          result.updatedAt = <Typography variant="body2"> {new Date(result.updated_at).toLocaleString()}</Typography>;
          result.certificates = <CertificateShowButton recordID={result.id} />;
          result.environmentChip = (
            <ReferenceArrayField label="Environments" reference="environment" source="environments" record={result}>
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          );
        });

        setClone(temp);
      }
    };

    processData();
  });

  if (isPending || !data) return null;

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these CAs?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'ca',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'CA',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable data={clone} headers={caHeaders} showActions={true} zebra={false} addRow={() => redirect('create', 'ca')} bulkAction={confirmBulkDelete} />
    </>
  );
};

export const CertTable = ({ id }) => {
  const redirect = useRedirect();
  const { data, isLoading } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isLoading || !data) return null;

  const clone = structuredClone(data);

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  clone?.forEach((result, idx) => {
    result.edit = <MyEdit url={`/ca/${id}/certificate/${result.id}`} key={`edit-${idx}`} />;
  });

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Certificates?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: `ca/${id}/certificate`,
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Certificate',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        data={clone}
        headers={certificateHeaders}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', `ca/${id}/certificate`)}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const CAForm = ({ edit, ...props }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const getEnvironments = async () => {
      if (!loading) {
        setLoading(true);
        const list = await dataProvider.getList('environment', {
          filter: {},
          sort: { field: 'id', order: 'DESC' },
          pagination: { page: 1, perPage: 25 },
        });
        setChoices(list?.data?.map((r) => ({ id: r.id, text: r.name })));
      }
    };

    getEnvironments();
  }, []);

  if (!choices) return null;

  return (
    <SimpleForm disableInvalidFormNotification {...props} toolbar={<CustomToolbar showDelete={edit ? true : false} redirectPath={'/ca'} resource="ca" />}>
      <CarbonTextInputField
        source="name"
        label="Name"
        id="name"
        validate={[required('Name is required'), regex(/^[a-zA-Z0-9_-]+$/, 'Enter a valid name consisting of letters, numbers, underscores or hyphens.')]}
      />
      <CarbonTextInputField source="description" label="Description" />
      <CarbonTextArea label="Cert" inputProps={{ style: { fontFamily: 'Monospace' } }} source="cert" rows={20} />
      <BooleanInput fullWidth source="public" />

      <MyMultiSelect
        source="environments"
        choices={choices}
        parse={(v) => {
          return v;
        }}
        title="Environments"
      />

      <JsonEditor source="meta" />
      {edit && <CertificateShowButton recordID={record?.id} />}
    </SimpleForm>
  );
};

export const CACreate = ({ props }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/ca');
    refresh();
    notificationCtx.add({ msg: 'CA created', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err, resource: 'ca', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/ca" />
      <CAForm
        defaultValues={{
          cert: null,
          name: '',
          description: '',
          environments: [],
          public: false,
        }}
      />
    </CreateBase>
  );
};

export const CAEdit = ({ props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const onSuccess = () => {
    notificationCtx.add({ msg: 'CA updated successfully', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err, resource: 'ca' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/ca" />
      <CAForm edit={true} {...props} />
    </EditBase>
  );
};
export const CAList = () => (
  <>
    <MyBreadcrumbs path={'/ca'} />
    <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
      <CATable />
    </List>
  </>
);

export const CACertificateForm = ({ myedit, mypath, myresource, ...props }) => {
  const record = useRecordContext();

  if (myedit && !record) return null;

  return (
    <SimpleForm
      disableInvalidFormNotification
      record={record}
      {...props}
      toolbar={<CustomToolbar showDelete={myedit ? true : false} redirectPath={mypath} resource={myresource} />}
    >
      <CarbonTextInputField
        source="name"
        label="name"
        validate={[required('Name is required'), regex(/^[a-zA-Z0-9_-]+$/, 'Enter a valid name consisting of letters, numbers, underscores or hyphens.')]}
      />
      <CarbonTextInputField source="description" label="description" />
      <CarbonTextInputField
        source="fingerprint"
        label="certificate"
        validate={[regex(/^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2})*$/, 'Enter a valid name consisting of letters, numbers, and colons.')]}
      />
    </SimpleForm>
  );
};

export const CACertificateList = () => {
  const { id } = useParams();

  return (
    <>
      <MyBreadcrumbs path={`/ca/${id}/certificate`} />
      <List resource={`ca/${id}/certificate`} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false} hasCreate>
        <CertTable id={id} />
      </List>
    </>
  );
};

export const CACertificateEdit = () => {
  const { id, certificateId } = useParams();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const onSuccess = () => {
    notificationCtx.add({ msg: 'Certificate updated successfully', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err, resource: `ca/${id}/certificate` });
  };

  return (
    <EditBase resource={`ca/${id}/certificate`} id={certificateId} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={`/ca/${id}/certificate`} />
      <CACertificateForm myedit mypath={`/ca/${id}/certificate`} myresource={`ca/${id}/certificate`} />
    </EditBase>
  );
};

export const CACertificateCreate = () => {
  const { id } = useParams();
  const notificationCtx = React.useContext(NotificationContext);
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    notificationCtx.add({ msg: 'Certificate updated successfully', status: 'success' });
    redirect(`/ca/${id}/certificate`);
  };

  const onError = (err) => {
    handleError({ err: err, resource: `ca/${id}/certificate`, create: true });
  };

  return (
    <CreateBase resource={`ca/${id}/certificate`} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={`/ca/${id}/certificate`} />
      <CACertificateForm
        myedit={false}
        mypath={`/ca/${id}/certificate`}
        defaultValues={{
          name: '',
          description: '',
          fingerprint: null,
        }}
      />
    </CreateBase>
  );
};

const CertificateShowButton = ({ recordID }) => {
  const redirect = useRedirect();
  const handleClick = (event) => {
    redirect(`/ca/${recordID}/certificate`);
    event.stopPropagation();
  };
  return (
    <Button onClick={handleClick} renderIcon={FingerprintIcon} kind="ghost">
      Show Certificates
    </Button>
  );
};
